<template>
  <div class="contentContainer container">
  <div >
    <div class="card py-3 px-3">
      <div class="block">
        <h4>{{ $t('billing.period_selection') }}</h4>
        <date-range-picker
            ref="picker"
            :ranges="calendarTranslate.ranges[$i18n.locale]"
            v-model="dateRange"
            :opens="'right'"
            :locale-data="calendarTranslate.calendar[$i18n.locale]"
        >
          <template v-slot:input="picker">
            <i class="feather icon-calendar m-r-5"></i>
            {{ moment(picker.startDate).format('DD.MM.YYYY') }} - {{ moment(picker.endDate).format('DD.MM.YYYY') }}
          </template>
            <div slot="footer" slot-scope="data" class="slot d-lg-flex justify-content-xl-end " >
              <div class="d-flex align-items-center">
                <b >{{ data.rangeText }}</b>
              </div>
              <div class="d-grid p-10 d-lg-flex ">
                <b-button @click="data.clickApply" variant="primary" v-if="!data.in_selection" class="acceptButton btn">{{ $t('billing.applyLabel') }}</b-button>
                <button @click="data.clickCancel" class="btn btn-secondary">{{ $t('billing.cancelLabel') }}</button>
              </div>
            </div>
        </date-range-picker>
      </div>

      <div class="block" v-if="!isOperator">
        <users-list
            ref="userList"
            :users-list="currentUserList"
            :users-loading="usersLoading"
            :users-limit="usersLimit"
            @setUsers="setUsers"
            @changePage="changeUsersOffset"
        />


<!--        <div class="block-text-title">Выбор пользователей</div>-->
<!--        <b-form-group-->
<!--            v-slot="{ ariaDescribedby }"-->
<!--        >-->
<!--          <b-form-checkbox-->
<!--              v-for="user in currentUserList"-->
<!--              v-model="usersIds"-->
<!--              :key="user.id"-->
<!--              :value="user.id"-->
<!--              :aria-describedby="ariaDescribedby"-->
<!--          >-->
<!--            {{user.login}}-->
<!--          </b-form-checkbox>-->
<!--        </b-form-group>-->
      </div>


      <div class="block" v-if="!isOperator" style="margin-bottom: 1rem">
<!--        <div class="block-text-title">Фильтр событий</div>-->
<!--        <div>-->
<!--          <b-form-select v-model="selectedEvent" :options="events"></b-form-select>-->
<!--        </div>-->

<!--        <events-list></events-list>-->
        <events-list
            :events="events"
            @setEvents="setEvents"
        />
      </div>

      <div class="block" v-if="selectedTab === 0 && !isOperator">
        <h4>{{ $t('billing.view') }}</h4>
<!--        <div>-->
<!--          <b-form-select v-model="selectedTableView" :options="view"></b-form-select>-->
<!--        </div>-->
        <div class="form-radio" style="margin-bottom: 1rem">
          <form>
            <div class="radio radio-inline" v-for="current in view" @click="setView(current)">
              <label>
                <input type="radio" name="radio" :value="current" v-model="selectedTableView" checked="checked">
                <i class="helper"></i>{{ $t('billing.'+current) }}
              </label>
            </div>
          </form>
        </div>
      </div>

      <h4 v-if="!isOperator">{{ $t('billing.account_action') }}</h4>

      <div class="tables" >
        <b-tabs v-model="selectedTab" type="is-toggle" expanded v-if="isSuperAdmin || isFrontliner">
          <b-tab style="color: black" class="textPrimary" :title="$t('billing.company')">
            <div>
              <company-view
                  :role="roles"
                  :is-loading="isLoading"
                  :company-transactions="companyTransactions"
                  :selected-table-view="selectedTableView"
              />
            </div>
          </b-tab>
          <b-tab class="textPrimary" :title="$t('billing.client')">
            <div>
              <client-view
                  :role="roles"
                  :is-loading="isLoading"
                  :client-transactions="clientTransactions"
              />
            </div>
          </b-tab>
        </b-tabs>

        <div class="block" v-if="isDeveloper || isAdmin || isOperator">
          <company-view
              :role="roles"
              :is-loading="isLoading"
              :company-transactions="companyTransactions"
              :selected-table-view="selectedTableView"
          />
        </div>
      </div>

    </div>

  </div>
    <div v-if="pageLoad" class="d-flex justify-content-center">
      <b-spinner></b-spinner>
    </div>

  </div>
</template>

<script>
import CompanyView from "@/components/billing/companyView";
import ClientView from "@/components/billing/clientView";
import DateRangePicker from 'vue2-daterange-picker'
import "@/components/billing/pickerstyle.css"
import moment from "moment";
import {mapGetters} from "vuex";
import calendarTranslate from "@/configs/calendarTranslate";
import UsersList from "@/components/billing/usersList";
import EventsList from "@/components/billing/eventsList";



export default {
  name: "Billing",
  components: {EventsList, UsersList, ClientView, CompanyView, DateRangePicker },
  setup() {
    return {
      moment,
      calendarTranslate
    }
  },
  data() {
    return {
      selectedTableView: "detail",
      selectedTab: 0,
      selectedEvent: undefined,
      isLoading: false,
      pageLoad: false,
      dateRange: {
        startDate: moment().subtract(7, 'days'),
        endDate:   moment(),
      },
      usersIds: [],
      events: [
          "CHILD_DEACTIVATION",
          "DIRECT_INCOME",
          "PARENT_UNLIM_CHANGE",
          "SEARCH_OUTCOME",
          "DIRECT_OUTCOME",
          "DEACTIVATION",
          "PARENT_DEACTIVATION",
          "UNLIM_CHANGE",
          "MONITORING_OUTCOME"
      ],
      view: [
        "detail",
        "summary",
        "daily"
      ],
      usersLoading: false,
      usersOffset: 0,
      usersLimit: 300,
    }
  },
  methods:{
    setView(val){
      this.selectedTableView = val;
    },
    setUsers(val){
      this.usersIds = val
    },

    setEvents(val){
      this.selectedEvent = val;
    },

    changeUsersOffset(val){
      this.usersOffset = val;
      this.getUsersList();
    },


    selectedCurrentUser(){},
    getUsersList() {
      this.pageLoad = true;
      this.usersLoading = true;
      let payload = {
        filters: {
          same_tenant: true
        },
        offset: this.usersOffset,
        limit: this.usersLimit
      }
      this.$store.dispatch("billing/getUserList", payload).then(() => {
        this.usersLoading = false;
        this.pageLoad = false;
        this.$refs['userList'].selectUsers()
      });
    },
    setDates(){
      this.dateRange.endDate = moment();
      this.dateRange.startDate = moment().subtract(7, 'days').toDate()
    },

    getTransaction(){
      if (this.usersIds.length > 0){
        this.isLoading = true;
        let payload = {
          view: this.selectedTableView,
          offset: 0,
          limit: 500,
          filters: {
            created_from: moment(this.dateRange.startDate).startOf('day').format('X'),
            created_to: moment(this.dateRange.endDate).endOf('day').format('X'),
            subject_id: this.usersIds
          }
        }
        if (typeof this.selectedEvent !== 'undefined'){
          payload.filters.reason = this.selectedEvent
        }
        if (this.selectedTab === 0){
          this.$store.dispatch('billing/getCompanyTransactions', payload).finally(()=>{
            this.isLoading = false;
          })
        } else {
          payload.view = 'daily'
          this.$store.dispatch('billing/getClientTransactions', payload).finally(()=>{
            this.isLoading = false;
          })
        }
      }
    },

  },
  watch:{
    selectedTableView(){
      this.getTransaction();
    },
    selectedEvent(){
      this.getTransaction();
    },
    usersIds(){
      if (this.usersIds.length){
        this.getTransaction();
      } else {
        this.$store.dispatch('billing/cleanTransactions')
      }
    },
    'dateRange.endDate': function (){
      this.getTransaction();
    },
    'dateRange.startDate': function (){
      this.getTransaction();
    },

    selectedTab(){
      this.usersIds = [];
      this.$refs['userList'].unselectAll();
      this.usersLoading = true;
      this.isLoading = true;

      if (this.selectedTab === 1){
        let payload = {
          filters: {
            same_tenant: false
          }
        }
        this.$store.dispatch("billing/getUserList", payload).then(() => {
          this.isLoading = false;
          this.usersLoading = false;
          this.$refs['userList'].selectUsers()
        });
      } else {
        let payload = {
          filters: {
            same_tenant: true
          }
        }
        this.$store.dispatch("billing/getUserList", payload).then(() => {
          this.isLoading = false;
          this.usersLoading = false;
          this.$refs['userList'].selectUsers()
        });
      }

    },
  },
  computed:{
    ...mapGetters('billing', ["clientTransactions", "companyTransactions", "currentUserList"]),
    ...mapGetters("auth", ["user","roles","user"]),
    isSuperAdmin(){
      return this.roles.includes('superadmin')
    },
    isDeveloper(){
      return this.roles.includes('developer')
    },
    isFrontliner(){
      return  this.roles.includes('frontliner')
    },
    isAdmin(){
      return this.roles.includes('admin')
    },
    isOperator(){
      return this.roles.includes('operator')
    },
  },
  mounted() {
    this.setDates();
    if (typeof this.roles !== 'undefined' && !this.isOperator){
      this.getUsersList();
    }
    if (this.isOperator && typeof this.user !== 'undefined'){
      this.usersIds.push(this.user.id)
    }
  }
}
</script>

<style >

  body .border-checkbox-group.border-checkbox-group-primary {
    display: block;
  }
  body .daterangepicker {
    z-index: 10 !important
  }
  @media screen and (min-width: 768px){
    
    .acceptButton{
      margin-right: 8px;
    }
  }
  @media screen and (max-width: 768px){
    body .daterangepicker.show-ranges {
      min-width: 340px !important;
    }
    .acceptButton{
      margin-bottom: 8px;
    }
  }

</style>

<style lang="scss">
body .daterangepicker .acceptButton{
  background-color: #4099ff !important;
}

body .daterangepicker .acceptButton:hover{
  background-color: #73b4ff !important;
}
.tables{
  .nav-link{
    color: rgb(64, 153, 255);
  }
}
.daterangepicker td.active{
  background-color: #4099ff;
}
.daterangepicker td.active:hover{
  background-color: #73b4ff;
}

.daterangepicker .ranges .active {
  background-color: #4099ff !important;
}
.daterangepicker .ranges .active:hover {
  background-color: #73b4ff !important;
}
</style>